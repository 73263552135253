<template>
  <div>
    <b-table-filter
      :items="rows"
      :fields="fields"
      title="Báo Cáo Giáo Viên"
      :perPage="perPage"
      :pageLength="pageLength"
      :isBusy="busy"
      :totalRows="totalRow"
    >
      <!-- <template #rightAction>
        <div>
          <b-button size="sm" variant="info"><feather-icon icon="CalendarIcon" /> Hôm Nay</b-button>
          <b-button size="sm"  variant="success"><feather-icon icon="CalendarIcon" /> Tuần Này</b-button>
          <b-button size="sm" variant="warning"><feather-icon icon="CalendarIcon" /> Tháng Này</b-button>
          <b-button size="sm"><feather-icon icon="CalendarIcon" /> Tháng Trước</b-button>
        </div>
      </template> -->
      <template #filterTable>
        <td style="width: 15%">
          <b-form-datepicker placeholder="Từ Ngày" size="sm" v-model="fromDate" />
        </td>
        <td style="width: 15%">
          <b-form-datepicker placeholder="Đến Ngày" size="sm" v-model="toDate" />
        </td>
        <td>
          Nếu không chọn Ngày Sẽ Lấy 5 ngày gần Nhất
        </td>
        <td>
          <div style="float:right">
            <b-button variant="warning"  size="sm"  @click="onSubmitFilter" style="margin-right: 5px"><feather-icon icon="SearchIcon" /> Xem Báo Cáo</b-button>
          </div>
        </td>
      </template>
    </b-table-filter>
  </div>
</template>

<script>
import {
  BFormDatepicker, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BFormDatepicker,
    BButton,
    BTableFilter,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      busy: false,
      totalRow: 1,
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'STT',
          key: 'id',
        },
        {
          label: 'Họ Tên Giáo Viên',
          key: 'fullName',
        },
        {
          label: 'Số Lớp Dạy',
          key: 'phone',
        },
        {
          label: 'Email',
          key: 'email',
        },
        {
          label: 'Ngày Tạo',
          key: 'created_at',
        },
      ],
      rows: [],
      fromDate: null,
      toDate: null,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    onSubmitFilter() {
      this.busy = false
      console.log('onSubmitFilter', this.fromDate, this.toDate)
      this.rows = [
        {
          id: 1,
          fullName: 'Danh Sinh',
          phone: '096-998-5002',
          email: '90100@gmail.com',
          created_at: 111111,
        },
      ]
    },
    onReset() {
      this.fromDate = null
      this.toDate = null
    },
  },
}
</script>
